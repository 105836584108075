import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

function CorporateCard({ image, title, description }) {

  return (
    <Card sx={{
      "&:hover": {
        transform: "scale(1.01)",
        backgroundColor: "rgb(252,252,252)",
      }, width: "100%", height: "100%"
    }}
    >
      <MDBox p={6} sx={{ width: "100%", height: "100%" }} >
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={`${process.env.PUBLIC_URL}${image}`}
            alt={title}
            size="xxl"
            variant="rounded"
            sx={{ p: 0, mt: -2, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
          />
        </MDBox>
        <MDBox my={0} lineHeight={0}>
          <MDTypography color="secondary" variant="body2" fontWeight="bold" pb={3} pt={3} >
            {title}
          </MDTypography>
          <MDTypography p={0} variant="body2" fontWeight="light" color="text" sx={{ height: "100%" }}>
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CorporateCard;
