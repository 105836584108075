import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

function PhotoCard(props) {

    let url = props.url

    return (
        <Card sx={{
            "&:hover": {
                transform: "scale(1.01)",
                backgroundColor: "rgb(252,252,252)",
            }, width: "100%", height: "100%"
        }}
        >
            <MDBox p={6} sx={{ width: "100%", height: "100%" }} >
                <MDBox display="flex" alignItems="center">

                    <MDAvatar
                        src={url}
                        alt={url}
                        size="xxl"
                        variant="rounded"
                        sx={{ width: "100%", height: "auto", p: 0, mt: -2, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
                    />
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default PhotoCard;
