import React, { useEffect, useState } from 'react'
import DashboardLayout from 'tools/LayoutContainers/DashboardLayout'
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

export default function ATBI() {

  return (
    <DashboardLayout>
      <DashboardNavbar onSsearch={false} />
      <MDBox display="flex" alignItems="center">
        <Card sx={{ overflow: "visible" }} p={3}>
          <MDBox p={3}>
            <MDBox mb={3} p={4}>
              <MDTypography variant="h2" pt={6} fontWeight="medium">
                ALLIED TRADES OF THE BAKING INDUSTRY
              </MDTypography>
              <MDTypography variant="h5" fontWeight="medium">
                Welcome to the ATBI Website
              </MDTypography >
              <MDBox p={6} sx={{ width: "100%", }} >
                <MDBox display="flex" alignItems="center" justifyContent="center">
                  <MDAvatar
                    src={`${process.env.PUBLIC_URL}/atbi_logo_100.jpg`}
                    alt="atbi" 
                    variant="rounded"
                    sx={{ width:"50%", height:"auto", minWidth:"200px", p: 0, mt: -2, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
                  />
                  </MDBox>
                </MDBox>
                <MDTypography variant="h5" pt={6} fontWeight="medium">
                  Partners in Baking
                </MDTypography>
                <MDTypography variant="h6" pt={2} fontWeight="light">
                  Founded in 1920, the Allied Trades of the Baking Industry is an organization which exists to serve the grain-based food industry through cooperation between a large cross-section of suppliers to the wholesale manufacturers that each day provide our country with bread, rolls, cereals, cakes, cookies, crackers, tortillas and any number of other items that incorporate grains as their base. It is an organization that values the relationships which have been built between the supplier and the manufacturer over the 99 years since its inception.
                </MDTypography>
              </MDBox>
              <MDBox mb={3} p={4}>
                <MDTypography variant="h5" fontWeight="medium">
                Secretary/Treasurer
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium">
                  <p>James Boddicker</p>
                </MDTypography>
                <MDTypography variant="caption" fontWeight="light">
                  <p>4801 Main St. Suite 650</p>
                  <p>Kansas City, MO 64112</p>
                  <p>(913) 669-1763</p>
                  <p>jboddicker@sosland.com</p>
                </MDTypography>
              </MDBox>
            </MDBox>
        </Card>
      </MDBox >
    </DashboardLayout >
  )
}
