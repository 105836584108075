import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Outlet, Navigate, useLocation } from 'react-router-dom'

import Sidenav from './Sidenav'
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "./context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { routes } from './routes'


// import { useAuthValue } from "context/AuthContext";

export default function PrivateRoute() {

  
  const [controller, dispatch] = useMaterialUIController();

  

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  function getSidenavRoutes() {



    let userType = "anonymous"

    let allRoutes = routes
    var userRoutes = []
   
    Object.entries(allRoutes).map(([key, value]) => {

      if (value.access) {

        if (value.access.includes(userType)) {

         

          if (value.collapse) {

          
            var tempCollaspe = []
            value.collapse.map((d, i) => {


              if (d.access && d.access.includes(userType)) {

                tempCollaspe[i] = d

              }
            })

            value.collapse = tempCollaspe

            userRoutes[key] = value

          } else {

            userRoutes[key] = value
          }

          userRoutes[key] = value

        }

      }
    });

    return userRoutes
  }

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="ATBI"
        routes={getSidenavRoutes()}
        userData=""
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Outlet />
     
    </>
   
  )
  
}



