import React, { useState, useEffect } from 'react'
import DashboardLayout from "tools/LayoutContainers/DashboardLayout";
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import TradesmanCard from './tradesmanCard';
import { getStorage} from "firebase/storage";

export default function Tradesman() {

    const storage = getStorage();
    
    const docs = [
        {
            name:"Allied Tradesman 2000",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI00.pdf?alt=media&token=bce39407-3250-465e-916e-a19ab339bc47"
        },
        {
            name:"Allied Tradesman 2001",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI01.pdf?alt=media&token=d6866081-9cdd-4e28-ad55-3c63c8fa5851"
        },
        {
            name:"Allied Tradesman 2002",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI02.pdf?alt=media&token=bcbf6088-fdca-4dcc-a29c-4d07cc4aba86"
        },
        {
            name:"Allied Tradesman 2003",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI03.pdf?alt=media&token=ba4ca8ee-23c4-42e8-a795-3a7464c4c6eb"
        },
        {
            name:"Allied Tradesman 2004",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI04.pdf?alt=media&token=735f2d89-79b1-42cb-a9f0-55a2190074a3"
        },
        {
            name:"Allied Tradesman 2006",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI06.pdf?alt=media&token=a7836529-cd0c-4737-b011-221b9ffb8b3e"
        },
        {
            name:"Allied Tradesman 2007",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI07.pdf?alt=media&token=7ec32e15-f447-4d8f-ba1d-4cd29d9d0277"
        },
        {
            name:"Allied Tradesman 2008",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI08.pdf?alt=media&token=e289f842-0ad9-4637-9ff3-831e1dd11b77"
        },
        {
            name:"Allied Tradesman 2009",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI09.pdf?alt=media&token=88209115-4321-433b-b525-9ff9d2ac57d5"
        },
        {
            name:"Allied Tradesman 2010",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI10.pdf?alt=media&token=4296bb93-46c1-42c0-9972-bf72ef814958"
        },
        {
            name:"Allied Tradesman 2011",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI11.pdf?alt=media&token=af3bd45c-10d7-445e-b764-ab53f47149e8"
        },
        {
            name:"Allied Tradesman 2012",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI12.pdf?alt=media&token=3506da65-54a1-432b-a75a-b68bffe6f0d4"
        },
        {
            name:"Allied Tradesman 2013",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI13.pdf?alt=media&token=35e0aae3-66fc-4e2f-8693-8e2bfdc2c463"
        },
        {
            name:"Allied Tradesman 2016",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI16.pdf?alt=media&token=74758876-5317-4809-9a89-2687b9d53e29"
        },
        {
            name:"Allied Tradesman 2017",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI17.pdf?alt=media&token=8a04c46e-f35e-4a9e-8abe-f5a842d579ba"
        },
        {
            name:"Allied Tradesman 2018",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI18.pdf?alt=media&token=2b83ebe8-be5b-439e-960e-50c681325f5c"
        },
        {
            name:"Allied Tradesman 2019",
            url:"https://firebasestorage.googleapis.com/v0/b/atbi-4ab08.appspot.com/o/tradesmans%2FATBI18.pdf?alt=media&token=2b83ebe8-be5b-439e-960e-50c681325f5c"
        }
    ]

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={4} p={2} alignItems="stretch" >
                {docs.map(d => (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`grid${d['title']}`} >
                            <a href={d['url']} target="_blank" rel="noreferrer">
                                <TradesmanCard name={d['name']} url={d['url']} key={d['url']} />
                            </a>
                        </Grid>
                    </>
                ))}
            </Grid>
        </DashboardLayout>
    )
}
