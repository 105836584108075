import DataTable from 'tools/Tables/DataTable';
import React, { useEffect, useState } from 'react'
import DashboardLayout from 'tools/LayoutContainers/DashboardLayout'
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function PastPresidents() {

    const presidents_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }] }
    const presidents_rows = {
        rows: [

            {
                name: "Dean Modglin (2021/2022) LeSaffre"
            },

            {
                name: "DJ LeCrone (2019/2020) LeMatic"
            },

            {
                name: "John Hellman (2017/2018) - Retired - McGahee, Lacy & Associates"
            },
            {
                name: "Thomas McCurry (2015/2016) Cain Food"
            },

            {
                name: "Rick McGrath (2013/2014) Lockton Companies"
            },

            {
                name: "Tim S. Miller (2011/2012) - Retired - Grain Craft"
            },
            {
                name: "Gary M. Edwards (2009/2010) - Retired - Lallemand"
            },

            {
                name: "Brad Burris (2007/2008) D.Thomas & Associates"
            },

            {
                name: "Mike Gude (2005/2006) Sosland Publishing"
            },
            {
                name: "Gary Cain (2003/2004) - Retired Cain Food"
            },

            {
                name: "Fred Springer (2001/2002) - Retired - Burford Corp"
            },

            {
                name: "John Riesch - Retired - Lesaffre"
            },

            {
                name: "William T. McCurry - Retired - Manildra"
            }
        ]
    }

    return (
        <DashboardLayout>
            <DashboardNavbar onSsearch={false} />
            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                ATBI Previous Presidents
            </MDTypography >
            <MDBox display="flex" alignItems="center">
                <Card sx={{ overflow: "visible", width: "100%" }} p={3}>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="caption" fontWeight="medium">
                                Presidents
                            </MDTypography >
                            <DataTable table={Object.assign({}, presidents_columns, presidents_rows)} />
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}
