import React, { useEffect, useState } from 'react'
import DashboardLayout from 'tools/LayoutContainers/DashboardLayout'
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function History() {

    return (
        <DashboardLayout>
            <DashboardNavbar onSsearch={false} />
            <MDBox display="flex" alignItems="center">
                <Card sx={{ overflow: "visible" }} p={3}>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium">
                                History of the ATBI
                            </MDTypography >
                            <MDTypography variant="h6" pt={2} fontWeight="light">
                                <p>Founded in 1920 at the suggestion of a baker, the mission of the Allied Trades of the Baking Industry (ATBI) remains relatively unchanged today. It is to the credit of that baker, Gordon Smith Sr. of Mobile, AL, to tap into the energy and enthusiasm of the allied trades to promote and service the baking industry. Some twenty years later, Gordon was introduced as the "Daddy of the Allied Trades" and inducted as an Honorary Life Member of the ATBI at the annual meeting on October 21, 1952.</p>
                                <br/>
                                <p>The organization born of the foresight of a baker continues to thrive in a business environment vastly changing, even for a mature industry like baking. However, the purpose and focus of the ATBI remains very traditional. When incorporated as a nonprofit organization the stated purpose was to promote baking industry progress and to build good will between the allied trades and the baking industry. It adopted the motto "Cooperation is Strength".</p>
                                <br/>
                                <p>From the very beginning of ATBI, the makeup of officers and the board reflected a broad cross-section of suppliers to the baking industry such as milling, ingredients, packaging and equipment. The current 30-member board and four officers remain similarly diverse in the goods and services they represent to the industry. The geographic residence of board members has also been a consideration of the ATBI nominating committee to ensure that dialog on matters put before the board reflects perspectives from all areas of the country.</p>
                                <br/>
                                <p>ATBI places a lot of value on the relationship that it has with the baking industry and its venerable associations including: a commitment to fund an annual scholarship to the American Institute of Baking has been a priority for many years; special projects with the American Bakers Association (ABA) such as sponsorship of their annual golf tournament to raise funds for their Political Education Committee; donations to the Grain Foods Foundation (GFF) and the Wheat Foods Council (WFC) help support these respective organizations to educate consumers and promote consumption of bakery products. The annual American Society of Baking's (ASB) is also the site of the annual ATBI Early Bird Breakfast event and the annual business meeting for ATBI.</p>
                                <br/>
                                <p>In defining over time the contributions of ATBI as an organization, one cannot ignore the collective technical contributions to the betterment of the baking industry from allied companies. Innovations in formulations, process equipment, new ingredients with unique functionality, packaging materials to improve shelf life and convenience are just a few. The broad scope of these benefits can't be overlooked as simple self-serving but a true commitment to the industry by ATBI.</p>
                                <br/>
                                <p>This is likely what former ATBI President Jack Maynard had in mind when he suggested, in 1979, that the motto of the ATBI be changed to "Partners in the Baking Industry". That motto is now shown on the ATBI banner that hangs at all official ATBI functions. I think it would be fair to say in the final analysis that the best description of the ATBI is not a trade association but a companion association. In a world with fancy business clichés, companion may be a bit corny but it seems to work well for the ATBI membership that finds a great deal of pride in serving a venerable industry like baking. ATBI has found its niche.</p>
                                <br/>
                                <p>Look for the ATBI 100th Anniversary celebration coming up in 2023.</p>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}