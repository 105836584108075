import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "./form";
import { useTheme } from '@mui/material/styles';

function ContactUsForm(props) {

    const emailRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const companyRef = useRef()
    const phoneNumberRef = useRef()
    const messageRef = useRef()
    const theme = useTheme();
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    function handleSubmit(e) {

        if (firstNameRef.current.value == "" || lastNameRef.current.value == "") {
            alert("First and last name can not be empty. Please try again.")
            return
        }

        if (emailRef.current.value == "") {
            alert("Please enter an email address.")
            return
        }


        if (!regex.test(emailRef.current.value)) {
            alert("Not a valid Email address. Please enter a valid email address and try again.")
            return
        }

        let data = {

            "firstName": firstNameRef.current.value,
            "lastName": lastNameRef.current.value,
            "company": companyRef.current.value,
            "email": emailRef.current.value,
            "phoneNumber": phoneNumberRef.current.value,
            "message": messageRef.current.value,
        }

        props.callback(data)

    }

    return (

        <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox component="form" pb={3} px={3}>
                <Grid item xs={12} sm={12}>
                    <MDBox p={3} ml={-3}>
                        <MDTypography variant="h5">Contact Info</MDTypography>
                    </MDBox>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField key={"firstName"} label="First Name" inputRef={firstNameRef} placeholder="Required" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField key={"lasttName"} label="Last Name" inputRef={lastNameRef} placeholder="Required" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Company" inputRef={companyRef} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Email"
                            defaultValue=""
                            inputProps={{ type: "email" }}
                            inputRef={emailRef}
                            placeholder="Required"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Phone Number" inputRef={phoneNumberRef} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MDBox>
                            <MDTypography variant="h5">Message</MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Message" inputRef={messageRef} />
                    </Grid>
                    <MDBox ml="auto" mt="auto" pt={5}>
                        <MDButton variant="gradient" color="info" size="small" onClick={handleSubmit}>
                            Send Request
                        </MDButton>
                    </MDBox>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default ContactUsForm;
