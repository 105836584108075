import DataTable from 'tools/Tables/DataTable';
import React, { useEffect, useState } from 'react'
import DashboardLayout from 'tools/LayoutContainers/DashboardLayout'
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Regional() {

    const bcc_officers_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }, { Header: "Role", accessor: "role", width: "10%" },] }
    const bcc_officers_rows = {
        rows: [

            {
                name: "Ken Skrzypiec",
                company: "Brolite Products",
                role: "President"
            },
        ]
    }

    const mwatbi_officers_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }, { Header: "Role", accessor: "role", width: "10%" },] }
    const mwatbi_officers_rows = {
        rows: [

            {
                name: "James Boddicker",
                company: "Sosland Publishing",
                role: "President"
            }

        ]
    }

    const swat_officers_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }, { Header: "Role", accessor: "role", width: "10%" },] }
    const swat_officers_rows = {
        rows: [

            {
                name: "Bradley Cain",
                company: "Cain Food",
                role: "President"
            }
        ]
    }

    const nj_officers_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }, { Header: "Role", accessor: "role", width: "10%" },] }
    const nj_officers_rows = {
        rows: [

            {
                name: "John Johansen",
                company: "Malt Products",
                role: "President"
            }
        ]
    }

    return (
        <DashboardLayout>
            <DashboardNavbar onSsearch={false} />
            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                ATBI Regional Divisions
            </MDTypography >
            <MDBox display="flex" alignItems="center">
                <Card sx={{ overflow: "visible", width: "100%" }} p={3}>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium">
                                BCC- Bakers Courtesy Club of Chicago
                            </MDTypography >
                            <DataTable table={Object.assign({}, bcc_officers_columns, bcc_officers_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium">
                                MWATBI - Midwest Allied Trades of the Baking Industry
                            </MDTypography >
                            <DataTable table={Object.assign({}, mwatbi_officers_columns, mwatbi_officers_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium">
                                SWAT - Southwest Allied Trades of the Baking Industry
                            </MDTypography >
                            <DataTable table={Object.assign({}, swat_officers_columns, swat_officers_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium">
                                NJ#9 - New Jersey Allied Trades of the Baking Industry
                            </MDTypography >
                            <DataTable table={Object.assign({}, nj_officers_columns, nj_officers_rows)} />
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}
