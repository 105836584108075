import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "./form";
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';

function JoinATBIForm(props) {

    const [industry, setIndustry] = useState("ingredients")
    const [selectedIndusty, setSelectedIndustry] = useState()
    const [newMember, setNewMember] = useState(false);
    const [currentMember, setCurrentMemeber] = useState(false)
    const [agreement, setAgreement] = useState(false)

    const emailRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const companyRef = useRef()
    const titleRef = useRef()
    const phoneNumberRef = useRef()
    const addressRef = useRef()
    const stateRef = useRef()
    const industryRef = useRef()
    const referredByRef = useRef()

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const handleCheckChange = (event) => {
      
        switch (event.target.id) {
            case "newMember":
                setNewMember(event.target.checked)
                setCurrentMemeber(false)
                break;
            case "currentMember":
                setCurrentMemeber(event.target.checked)
                setNewMember(false)
                break;
            case "agreement":
                setAgreement(event.target.checked)
                break;
            default:
                break;
        }
    };

    const handleChange = (event) => {

        let selectedUserIndustry = event.target.value
        setSelectedIndustry(selectedUserIndustry)
        setIndustry(event.target.value);

    };


    function handleSubmit(e) {

        if (!agreement) {
            alert("You must agree to the terms of the association to submit your application")
            return
        }

        if (firstNameRef.current.value === "" || lastNameRef.current.value === "") {
            alert("First and last name can not be empty. Please try again.")
            return
        }

        if (emailRef.current.value === "") {
            alert("Please enter an email address.")
            return
        }


        if (!regex.test(emailRef.current.value)) {
            alert("Not a valid Email address. Please enter a valid email address and try again.")
            return
        }

        let data = {

            "firstName": firstNameRef.current.value,
            "lastName": lastNameRef.current.value,
            "company": companyRef.current.value,
            "title": titleRef.current.value,
            "address": addressRef.current.value,
            "cityStateZip": stateRef.current.value,
            "email": emailRef.current.value,
            "phoneNumber": phoneNumberRef.current.value,
            "industry": selectedIndusty,
            "newMember": newMember,
            "currentMember": currentMember,
            "termsAgreed": agreement

        }

        props.callback(data)

    }

    return (

        <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox component="form" pb={3} px={3}>
                <Grid item xs={12} sm={12}>
                    <MDBox p={3} ml={-3}>
                        <MDTypography variant="h5">Personal Details</MDTypography>
                    </MDBox>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField key={"firstName"} label="First Name" inputRef={firstNameRef} placeholder="Required" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField key={"lasttName"} label="Last Name" inputRef={lastNameRef} placeholder="Required" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Company" inputRef={companyRef} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Title" inputRef={titleRef} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Address" inputRef={addressRef} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="City, State, Zip" inputRef={stateRef} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Email"
                            defaultValue=""
                            inputProps={{ type: "email" }}
                            inputRef={emailRef}
                            placeholder="Required"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Phone Number" inputRef={phoneNumberRef} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <InputLabel>Industry Affliation</InputLabel>
                        <Select
                            labelId="selectable_category_label"
                            id="selectable_category"
                            inputRef={industryRef}
                            value={industry}
                            defaultValue="All"
                            onChange={handleChange}
                        >
                            <MenuItem value={"ingredients"}>Ingredients</MenuItem>
                            <MenuItem value={"equipment"}>Equipment</MenuItem>
                            <MenuItem value={"packaging"}>Packaging</MenuItem>
                            <MenuItem value={"consultant"}>Consultant</MenuItem>
                            <MenuItem value={"press"}>Press</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormControlLabel
                            label="New Member"
                            control={
                                <Checkbox
                                    id="newMember"
                                    checked={newMember}
                                    onChange={handleCheckChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormControlLabel
                            label="Current Member"
                            control={
                                <Checkbox
                                    id="currentMember"
                                    checked={currentMember}
                                    onChange={handleCheckChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Referred By" inputRef={referredByRef} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <FormControlLabel
                            label="In The Event of My Election, I Promise to Conform to the Bylaws of this Association"
                            control={
                                <Checkbox
                                    id="agreement"
                                    checked={agreement}
                                    onChange={handleCheckChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <MDBox ml="auto" mt="auto" pt={5}>
                        <MDButton variant="gradient" color="info" size="small" onClick={handleSubmit}>
                            Send Request
                        </MDButton>
                    </MDBox>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default JoinATBIForm;
