import React, { useState, useEffect } from 'react'
import MDBox from "components/MDBox";
import DashboardLayout from "tools/LayoutContainers/DashboardLayout";
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CorporateCard from './corporateCard';

export default function GrainLinks() {

    const links = [

        {
            "title": "American Association of Cereal Chemists",
            "description": "Advancing innovation across the broad disciplines of cereal grain science through research, education, collaboration, technical service, and advocacy.",
            "image": "/corporate_logos/cga.png",
            "website": "https://www.cerealsgrains.org/Pages/default.aspx"
        },

        {
            "title": "American Bakers Association",
            "description": "Serving Members from global wholesale baking companies and suppliers to baking industry entrepreneurs, the American Bakers Association (ABA) is the only bakery-specific national and state trade association, delivering results on priorities affecting the companies that feed the world.",
            "image": "/corporate_logos/aba.png",
            "website": "https://americanbakers.org/"
        },

        {
            "title": "American Institute of Baking",
            "description": "At AIB International, we focus solely on the food industry. Our dedicated teams are on the floor with you, working daily so you can strengthen your programs. We are passionate about food safety and committed to your future.",
            "image": "/corporate_logos/aib.png",
            "website": "https://www.aibinternational.com/"
        },

        {
            "title": "American Society of Baking",
            "description": "The American Society of Baking is a community of baking industry professionals who have joined together to provide continuing education, networking opportunities and professional development. The members include commercial bakers, engineers, food technologists and the suppliers of equipment, ingredients, and services in the grain-based food industry.",
            "image": "/corporate_logos/asb.png",
            "website": "https://www.asbe.org/"
        },

        {
            "title": "Bakery Equipment Manufacturers & Allieds",
            "description": "Bakery Equipment Manufacturers and Allieds (BEMA) enjoys a rich heritage serving the baking manufacturing industry.",
            "image": "/corporate_logos/bema.png",
            "website": "https://www.bema.org/"
        },

        {
            "title": "Grain Foods Foundation",
            "description": "We offer research-based information and resources to members, partners, influencers, policymakers, and consumers through a comprehensive communications campaign, conferences, webinars, research tools, social media, and more. GFF is committed to bringing fact-based information and common sense to the consumer.",
            "image": "/corporate_logos/gff.png",
            "website": "https://grainfoodsfoundation.org/"
        },

        {
            "title": "Wheat Foods Council",
            "description": "The WFC is an industry-wide partnership dedicated to increasing domestic wheat foods consumption through nutrition information, research, education and promotional programs",
            "image": "/corporate_logos/wfc.png",
            "website": "https://www.wheatfoods.org/wfc"
        },

        {
            "title": "Wheat Quality Council",
            "description": "A coordinated effort by breeders, producers & processors to improve wheat & flour quality.",
            "image": "/corporate_logos/wqc.png",
            "website": "http://www.wheatqualitycouncil.org//"
        }




    ]

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={4} p={2} alignItems="stretch" >
                {links.map(d => (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`grid${d['title']}`} >
                            <a href={`${d['website']}`} target="_blank" >
                                <CorporateCard image={d['image']} title={d['title']} description={d['description']} key={d['image']} />
                            </a>
                        </Grid>
                    </>
                ))}
            </Grid>
        </DashboardLayout>
    )
}
