import DataTable from 'tools/Tables/DataTable';
import React, { useEffect, useState } from 'react'
import DashboardLayout from 'tools/LayoutContainers/DashboardLayout'
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Directors() {

    const officers_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }, , { Header: "Role", accessor: "role", width: "10%" },] }
    const officers_rows = {
        rows: [

            {
                name: "Bill Gross",
                company: "PPC Flexible Packaging",
                role: "President"
            },

            {
                name: "Clay Miller",
                company: "Burford Corp.",
                role: "1st Vice President"
            },

            {
                name: "Peter Matthaei",
                company: "Dakota MB",
                role: "2nd Vice President"
            },

            {
                name: "James Boddicker",
                company: "Sosland Publishing",
                role: "Secretary / Treasurer"
            }

        ]
    }

    const board_members_2024_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }] }
    const board_members_2024_rows = {
        rows: [

            {
                name: "Jason Ward",
                company: "AMF Bakery"
               
            },

            {
                name: "Phil Burris",
                company: "D. Thomas & Associates"
              
            },

            {
                name: "Bradley Pollack",
                company: "Dawn Foods"
               
            },

            {
                name: "Jill Stang",
                company: "IFF"
                
            },
            {
                name: "Crockett Cobble",
                company: "Jennings Cobble"
               
            },

            {
                name: "Paul Lattan",
                company: "Avant Food Media"
              
            },

            {
                name: "John Johansen",
                company: "Malt Products"
               
            },

            {
                name: "Bradley Cain",
                company: "Cain"
                
            },

            {
                name: "Tom Benner",
                company: "LeSaffre"

            },

            {
                name: "Jeff Eller",
                company: "AB Mauri"   
            }
        ]
    }

    const board_members_2025_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }] }
    const board_members_2025_rows = {
        rows: [

            {
                name: "Chris Gillmar",
                company: "ADM"
               
            },

            {
                name: "Angie Miller",
                company: "Ardent Mills"
              
            },

            {
                name: "Dan Dooley",
                company: "Amcor"
               
            },

            {
                name: "Dennis Gunnell",
                company: "Formost Fuji"
                
            },
            {
                name: "Teresa Ruder",
                company: "LeMatic"
               
            },

            {
                name: "Bruce King",
                company: "Grain Craft"
              
            },

            {
                name: "Tim Tuzik",
                company: "Manildra Group USA"
               
            },

            {
                name: "Andrew Nutial",
                company: "William-Allen Co. Inc."
                
            },

            {
                name: "Kevin Sebby",
                company: "Miller Milling"
               
            },

            {
                name: "Morgan Murphy",
                company: "Mother Murphy's"
                
            }


        ]
    }

    const board_members_2026_columns = { columns: [{ Header: "Name", accessor: "name", width: "10%" }, { Header: "Company", accessor: "company", width: "10%" }] }
    const board_members_2026_rows = {
        rows: [

            {
                name: "Tim Marron",
                company: "BC Williams"
               
            },

            {
                name: "Glenn Rindfleisch",
                company: "SPF Plastic"
              
            },

            {
                name: "Scott Bieker",
                company: "Corbion"
               
            },

            {
                name: "Jason Stricker",
                company: "Shick Esteve"
                
            },
            {
                name: "Kurt Miller",
                company: "J&K Ingredients"
               
            },

            {
                name: "Chris Loeman",
                company: "Kwik Lok"
              
            },

            {
                name: "Audrey St. Onge",
                company: "Lallemand"
               
            },

            {
                name: "Chris Rohr",
                company: "BakeMark"
                
            },

            {
                name: "Rich Arnold",
                company: "Benchmark Automation"
               
            },

            {
                name: "Kenny Viau",
                company: "St. John's Packaging"
                
            }


        ]
    }

    return (
        <DashboardLayout>
            <DashboardNavbar onSsearch={false} />
            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                        The Allied Trades of the Baking Industry Board
                    </MDTypography >
            <MDBox display="flex" alignItems="center">
                <Card sx={{ overflow: "visible", width: "100%" }} p={3}>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>

                            <MDTypography variant="h5" fontWeight="medium">
                                Officers 2023/2024
                            </MDTypography >
                            <DataTable table={Object.assign({}, officers_columns, officers_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                                Class of 2024
                            </MDTypography >
                            <DataTable table={Object.assign({}, board_members_2024_columns, board_members_2024_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                                Class of 2025
                            </MDTypography >
                            <DataTable table={Object.assign({}, board_members_2025_columns, board_members_2025_rows)} />
                        </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                        <MDBox mb={3} p={4}>
                            <MDTypography variant="h5" fontWeight="medium" pb={3}>
                                Class of 2026
                            </MDTypography >
                            <DataTable table={Object.assign({}, board_members_2026_columns, board_members_2026_rows)} />
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}
