import { Route, Routes, useLocation } from "react-router-dom";
import ATBI from "layouts/pages/atbi";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ContactsIcon from '@mui/icons-material/Contacts';
import CollectionsIcon from '@mui/icons-material/Collections';
import GrassIcon from '@mui/icons-material/Grass';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Icon from "@mui/material/Icon";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import History from "layouts/pages/atbi/history";
import Directors from "layouts/pages/atbi/directors";
import CorporateMembers from "layouts/pages/atbi/corporateMembers";
import Regional from "layouts/pages/atbi/regionalDivisions";
import PastPresidents from "layouts/pages/atbi/pastPresidents";
import Tradesman from "layouts/pages/atbi/alliedTradesman";
import Photos from "layouts/pages/atbi/photos";
import GrainLinks from "layouts/pages/atbi/grainLinks";
import ContactUs from "layouts/pages/atbi/contactUs";
import JoinATBI from "layouts/pages/atbi/joinATBI";

export const routes = [

  {
    userProfile: true,
    type: "collapse",
    name: "profile",
    key: "profile",
    access: ["admin", "tech", "sales", "manager"],
    icon: <Icon fontSize="medium">receipt_long</Icon>,
  },

  { type: "divider", key: "divider-0" },

  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/atbi",
    component: <ATBI />,
    icon: <Icon fontSize="medium">home</Icon>,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "ATBI History",
    key: "history",
    route: "/history",
    component: <History />,
    icon: <Icon fontSize="medium">history</Icon>,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Board of Directors",
    key: "bod",
    route: "/directors",
    component: <Directors />,
    icon: <Icon fontSize="medium">people</Icon>,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Corporate Members",
    key: "cm",
    route: "/corporate-members",
    component: <CorporateMembers />,
    icon: <Icon fontSize="medium">groups</Icon>,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Regional Divisions",
    key: "rd",
    route: "/regional",
    component: <Regional />,
    icon: <HolidayVillageIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Previous Presidents",
    key: "pp",
    route: "/presidents",
    component: <PastPresidents />,
    icon: <WorkHistoryIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Allied Tradesman",
    key: "at",
    route: "/tradesman",
    component: <Tradesman />,
    icon: <ContactsIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "ATBI Photos",
    key: "photos",
    // route: "/atbi",
    // component: <ATBI />,
    icon: <CollectionsIcon />,
    access: ["anonymous"],
    collapse: [

      {
        name: "2019",
        key: "photos2019",
        route: "/2019",
        component: <Photos type="2019" />,
        access: ["anonymous"]
      },

      {
        name: "2016 ABA",
        key: "abaConvention2016",
        route: "/photos-2016-ABA",
        component: <Photos type="aba_2016" />,
        access: ["anonymous"]
      },
      {
        name: "2015 BCC Golf",
        key: "bccGolf2015",
        route: "/photos-2015-bcc",
        component: <Photos type="bcc_2015" />,
        access: ["anonymous"]
      },
      {
        name: "2015 TATA Golf",
        key: "golf2015",
        route: "/photos-tata-2015",
        component: <Photos type="tata_2015" />,
        access: ["anonymous"]
      },
      {
        name: "2009 MWATBI Golf",
        key: "golf2009",
        route: "/photos-mwatbi-golf-2009",
        component: <Photos type="mwatbi_golf_2009" />,
        access: ["anonymous"]
      },
      {
        name: "2009 ABA",
        key: "aba2009",
        route: "/photos-aba-2009",
        component: <Photos type="aba_2009" />,
        access: ["anonymous"]
      },
      {
        name: "2008 ABA",
        key: "aba2008",
        route: "/photos-aba-2008",
        component: <Photos type="aba_2008" />,
        access: ["anonymous"]
      },
      {
        name: "2008 Breakfast",
        key: "breakfast2008",
        route: "/photos-breakfast-2008",
        component: <Photos type="early_bird_breakfast_2008" />,
        access: ["anonymous"]
      },
      {
        name: "2008 Golf",
        key: "golf2008",
        route: "/photos-golf-2008",
        component: <Photos type="mwatbi_golf_2008" />,
        access: ["anonymous"]
      },
      {
        name: "2008 Holiday",
        key: "holiday-2008",
        route: "/photos-holiday-2008",
        component: <Photos type="mwatbi_holiday_party_2008" />,
        access: ["anonymous"]
      },
      {
        name: "History",
        key: "history",
        route: "/photos-history",
        component: <Photos type="history" />,
        access: ["anonymous"]
      }
    ]
  },

  {
    type: "collapse",
    name: "Grain-Based Links",
    key: "grains",
    route: "/grain-based",
    component: <GrainLinks/>,
    icon: <GrassIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },

  // {
  //   type: "collapse",
  //   name: "Guest Book",
  //   key: "guestBook",
  //   route: "/atbi",
  //   component: <ATBI />,
  //   icon: <MenuBookIcon />,
  //   noCollapse: true,
  //   access: ["anonymous"]
  // },

  {
    type: "collapse",
    name: "Contact Us",
    key: "contactUs",
    route: "/contact",
    component: <ContactUs />,
    icon: <ConnectWithoutContactIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },

  {
    type: "collapse",
    name: "Join ATBI Now!",
    key: "join",
    route: "/join-atbi",
    component: <JoinATBI />,
    icon: <BeenhereIcon />,
    noCollapse: true,
    access: ["anonymous"]
  },
]

export function routeTypes(userType) {

  return (
    <>

      <Route path="atbi" element={<ATBI />} />
      <Route path="history" element={<History />} />
      <Route path="directors" element={<Directors />} />
      <Route path="corporate-members" element={<CorporateMembers />} />
      <Route path="regional" element={<Regional />} />
      <Route path="presidents" element={<PastPresidents />} />
      <Route path="tradesman" element={<Tradesman />} />





    </>
  )



}
