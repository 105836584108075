import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import generatePdfThumbnails from 'pdf-thumbnails-generator';

function TradesmanCard({ name, url }) {

    const [t, setT] = useState()

    useEffect(() => {

        generatePdfThumbnails(url, 200).then((thumbnail) => {

            setT(thumbnail[0]['thumbnail'])
        })

    }, [])

    function generateName(name) {

        var u = name.split(".")[0]
        return `Allied Tradesman 20${u.slice(-2)}`

    }

    return (
        <Card sx={{
            "&:hover": {
                transform: "scale(1.01)",
                backgroundColor: "rgb(252,252,252)",
            }, width: "90%", height: "100%"
        }}
        >
            <MDBox p={6} sx={{ width: "100%", height: "100%" }} key={url} >
                <MDBox my={0} lineHeight={0}>
                    <MDTypography color="secondary" variant="body2" fontWeight="bold" pb={3} pt={3} >
                        {generateName(name)}
                    </MDTypography>
                    <MDBox display="flex" alignItems="center">
                        <img component="a" src={t ? t : `${process.env.PUBLIC_URL}/loading.gif`} alt='' />
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default TradesmanCard;