import React, { useState, useEffect } from 'react'
import DashboardLayout from "tools/LayoutContainers/DashboardLayout";
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import PhotoCard from './photoCard';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

export default function Photos(props) {

    const storage = getStorage();
    const [photos, setPhotos] = useState([])
    const [type, setType] = useState(props.type)

    useEffect(() => {

        getPhotos()

    }, [type])

    useEffect(() => {
        setType(props.type)
        setPhotos([])
    }, [props.type])

    function getPhotos() {

        const listRef = ref(storage, `/photos/${type}`);

        listAll(listRef).then((res) => {
            res.items.forEach((itemRef) => {
             
                getDownloadURL(ref(storage, `photos/${type}/${itemRef.name}`)).then((url) => {
                    setPhotos(photos => [...photos, url])
                })
            });
        }).catch((error) => {
            console.log("error", error);
        });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={4} p={2} alignItems="stretch" >
                {photos.map(d => (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`grid${d}`} >
                            <a href={`${d}`} target="_blank" rel="noreferrer" >
                                <PhotoCard url={d} key={d} />
                            </a>
                        </Grid>
                    </>
                ))
                }
            </Grid>
        </DashboardLayout>
    )
}