


// import ReactDOM from "react-dom";
import React from 'react'
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";

import Popup from 'react-popup';

import { PersistGate } from "redux-persist/integration/react";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";


// const container = document.getElementById("root")
const root =  ReactDOM.createRoot(document.getElementById("root"))
// import { UserContextProvider } from "../src/context/userContext"


// ReactDOM.render(

root.render(
  // <React.StrictMode>
  
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </BrowserRouter>
    
   
  // </React.StrictMode>
 
);
