import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { routes, getTypeRoutes } from "routes";
import PrivateRoute from "./PrivateRoute"
import { auth, db, logout } from './firebase'
import { useMaterialUIController } from "context";
import { routeTypes } from "routes";

import ATBI from "layouts/pages/atbi";

export default function App() {


 

  // const [currentUser, setCurrentUser] = useState(null)

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useLocation();

 

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

 


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {

      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        

        return <Route exact path={route.route} element={route.component} key={route.key} />;

      }
      return null;
    });



  return (
    <Fragment>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Fragment>
            <Routes>
              <Route path='/' element={<PrivateRoute />}>
                {getRoutes(routes)}
                {routeTypes("anonymous")}
                <Route exact path='/' element={<ATBI/>} />
              </Route>
            </Routes>
        </Fragment>
      </ThemeProvider>
    </Fragment>
  )
}