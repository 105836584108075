import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "tools/LayoutContainers/DashboardLayout";
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import ContactUsForm from "./contactUsForm";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';

function ContactUs() {

    const navigate = useNavigate();

    function handleSubmit(data) {
       

        emailjs.send('service_dghiwwp', 'template_dqf078r', data, 'user_FmRfhARLFRWibQhB8lF5T')
            .then((response) => {

                alert("Thank you for taking the time and submitting your details. Someone should follow up with you soon.")
                navigate("/")
            }, (err) => {
                console.log('FAILED...', err);
                alert("An error occurred while attempting to send your contact details. Please try again. ", err)
            });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox p={3}>
                <MDBox mb={3} p={4}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Drop us a line!
                    </MDTypography>
                </MDBox>
            </MDBox>
            <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                    <MDBox
                        justifyContent="center"
                        alignItems="center"
                        m={3}
                    >
                        <ContactUsForm callback={handleSubmit} />
                    </MDBox>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default ContactUs;
